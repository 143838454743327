












import { Vue, Component } from "vue-property-decorator";
import axios from "axios";
import Cookies from "js-cookie";
import { AuthModule } from "@/store/modules/auth";
import { getLoginUrl } from "@/services/auth.service";
import { UserModule } from "@/store/modules/user-module";
import router from "@/router";

@Component({
  name: "MsalCallback",
})
export default class extends Vue {
  public mounted() {
    this.validateCode();
  }

  public async validateCode() {
    if (Cookies.get("MSAL_STATE") !== this.$route.query.state) {
      router.push({ name: "Login", params: { errorMessage: "Incorrect state, please try again" } });
    }
    if (!this.$route.query.code) {
      router.push({ name: "Login", params: { errorMessage: "Something is wrong with Azure returned code" } });
    }
    await AuthModule.loginWithCode(this.$route.query.code as string, this.$route.query.state.toString());
  }
}
